<template>
  <mobile-screen :header="true" screen-class="gray-bg">
    <template v-slot:header>
      <top-header-menu-wrapper menu-class="about-header icon-hea1">
        <template v-slot:left>
          <router-link :to="{ name: backLinkName }">
            <icon icon="#cx-hea1-arrow-left" />
          </router-link>
        </template>
        <div class="component-title">
          {{
            displayLabelName(
              "applications",
              "add-application",
              "add-application"
            )
          }}
        </div>
      </top-header-menu-wrapper>
    </template>
    <section class="languages" v-if="applications && applications.length">
      <ul class="clebex-item-section pill">
        <li
          class="clebex-item-section-item full-right-underline"
          v-for="(application, index) in applications"
          :key="index"
        >
          <button
            class="clebex-item-content-wrapper"
            @click="setSelectedApplication(application)"
          >
            <span class="label">
              <span class="highlight">{{ application.name }}</span>
            </span>
            <span class="follow-up-icons">
              <span
                class="follow-up-icon-item"
                v-if="
                  selectedApplications &&
                    selectedApplications.length &&
                    selectedApplications.filter(
                      item => item.id === application.id
                    ).length
                "
              >
                <icon icon="#cx-app1-checkmark"></icon>
              </span>
            </span>
          </button>
        </li>
      </ul>
    </section>
  </mobile-screen>
</template>

<script>
import MobileScreen from "@/layouts/MobileScreen";
import TopHeaderMenuWrapper from "@/components/global/TopHeaderMenuWrapper";
import { mapActions, mapState } from "vuex";

export default {
  name: "AddApplications",
  components: {
    MobileScreen,
    TopHeaderMenuWrapper
  },
  props: {
    backLinkName: {
      type: String,
      required: true
    }
  },
  data() {
    return {};
  },
  computed: {
    ...mapState("applications", ["applications"]),
    ...mapState("companyDocuments", ["selectedApplications"])
  },
  created() {
    if (!this.applications) {
      this.getApplications();
    }
  },
  methods: {
    ...mapActions("applications", ["getApplications"]),
    ...mapActions("companyDocuments", ["setSelectedApplication"])
  }
};
</script>
